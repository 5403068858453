import React from 'react';

import './Person.scss';

export interface PersonProps {
  name: string;
  image: React.ReactNode;
  title: string;
  email?: string;
}

export const Person: React.FC<PersonProps> = ({ name, image, title, email }) => {
  return (
    <div className="Person">
      <div className="Person__Image">
        {image}
      </div>
      <p className="Person__Name">{name}</p>
      <p className="Person__Title">{title}</p>
      {email && <a href={`mailto:${email}`}>{email}</a>}
    </div>
  )
}