import React from "react"
import {Helmet} from "react-helmet";
import { Layout } from '../components/Layout';
import { Logo } from '../components/Logo';

import './index.scss';
import { JumboImage } from '../components/images/Jumbo.image';
import { Person } from '../components/Person';
import { PatrikImage } from '../components/images/Patrik.image';
import { OutiImage} from '../components/images/Outi.image';

const IndexPage = () => (
  <>
  <Helmet>
    <title>Wirmark</title>
  </Helmet>
  <Layout className="Home">
    <section className="Jumbo">
      <Logo />
      <JumboImage />
      <div className="Layover" />
    </section>

    <section className="Section">
      <h1>Software engineering and consultancy for all businesses</h1>
      <p>
        Wirmark provides services ranging from Fullstack Web Development to Data Analysis. Whether you are a Startup
        or already established business, we can help you with the modern technologies.
      </p>
      <p className="quote">"Builing beautiful software is more than just meeting the requirements"</p>

      <h2>Experiment your business concept with a Minumum Lovable Product</h2>
      <p>
        When establishing new businesses or evaluating new ideas, it's usually a good idea to start from
        something small and tangiable that can be done in a small period of time and doesn't require heavy investments.
      </p>
      <p>
        Minumum Viable Products (MVP) is an agile and lightweight way of concepting new ideas and getting results fast.
        By having just the minimum set of features it's easier to keep eye on in what really is essiential, 
        the very core of the idea. Later on if the MVP results being viable, the rest of the features will be built around it.
      </p>
      <h3>Measuring the MVP's viablity</h3>
      <p>
        For all rational decision making it's vital to have clear goals. Collecting well defined data and processing it equals to reliable measurebility -
        a compass while navigating through the unknown.

      </p>
    </section>
    <section className="Section">
      <h2>Cloud native solutions</h2>
      <p>
        We operate on Cloud. Leveraging could native architecture reduces the go-to live time significantly. While time is money,
        operating on Cloud saves both. 
      </p>
    </section>
    <section className="Section">
      <h2>Technologies and methods</h2>
      <p>
        We use the latest but already established technologies. Cloud native backend and infrastructure with modern frontend Frameworks
        like React and Angular. Creating and using Component based design systems, building applications will eventually turn out to be like assembling
        user interfaces from reusable building blocks - results in to consistant look and feel accross the application, for both users and developers.
      </p>
      <h3>Principal technologies</h3>
      <ul>
        <li>React and Angular, Server-Side Rendering</li>
        <li>Nodejs, Cloud functions (Lambda), Serverless</li>
        <li>AWS, Azure, Google Cloud</li>
        <li>Elasticsearch</li>
        <li>GTM, Google Analytics</li>
      </ul>
    </section>
    <section className="Section">
      <div className="persons D(flex) JC(center)">
        <Person image={<PatrikImage />} name="Patrik Keinonen" title="CEO / Fullstack Engineer" email="patrik@wirmark.com" />
        <Person image={<OutiImage />} name="Outi Jaanu" title="Board Memember / Finances / Data Analytics" />
      </div>
    </section>
  </Layout>
  </>
)

export default IndexPage
