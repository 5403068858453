import React from 'react';
import './Logo.scss';

export interface LogoProps {}

export const Logo: React.FC<LogoProps> = () => {
  return (
    <div className="Logo" />
  );
};
