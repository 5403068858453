import React from 'react';
import { Footer } from './Footer';
import classNames from 'classnames';

import './Layout.scss';

export interface LayoutProps {
  className?: string;
}

export const Layout: React.FC<LayoutProps> = ({ children, className }) => {
  const classes = classNames('Layout', className);
  return (
    <>
      <main className={classes}>{children}</main>
      <Footer />
    </>
  );
};
