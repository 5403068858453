import React from 'react';
import './Footer.scss';


export interface FooterProps {}

export const Footer: React.FC<FooterProps> = () => {
  return (
    <footer>
      <div className="Footer__Section">
        <strong>© Copyright Wirmark Oy</strong> - 0587669-3 - All rights reserved
      </div>
    </footer>
  );
};
